<template>
  <div>
   <Navbar page="Painel de Controle" video="https://www.youtube.com/watch?v=WSiDM_t5h2Y" />
   <div class="mx-4 my-4 md:mx-8">
     <div class="grid grid-cols-12 mb-4">
       <div class="col-span-12 md:col-span-8">
         <ul class="flex flex-wrap -mb-px text-sm font-medium text-center">
           <button type="button" @click="controle = 'Projeto';list()" :class="controle === 'Projeto' ? 'bg-yellow-400': ''" class="inline-block text-white my-1 dark:text-white transition duration-200 bg-gray-800 hover:bg-yellow-600 focus:shadow-sm focus:ring-none focus:ring-opacity-50 py-2 rounded-lg text-sm shadow-sm hover:shadow-md mx-1 w-full md:w-52 font-semibold text-center">
             Projeto
           </button>
     
           <button type="button" @click="controle = 'Programa';list()" :class="controle === 'Programa' ? 'bg-yellow-400': ''" class="inline-block text-white my-1 dark:text-white transition duration-200 bg-gray-800 hover:bg-yellow-600 focus:shadow-sm focus:ring-none focus:ring-opacity-50 py-2 rounded-lg text-sm shadow-sm hover:shadow-md mx-1 w-full md:w-52 font-semibold text-center">
             Programa
           </button>
         </ul>
       </div>
       <div class="col-span-12 md:col-span-4 md:justify-self-end">
         <router-link v-if="!$store.state.user.profissional && controle === 'Projeto'" :to="`/painel/form`" type="button" 
             class="inline-block text-white my-1 dark:text-white transition duration-200 bg-green-500 hover:bg-green-600 focus:shadow-sm focus:ring-none focus:ring-opacity-50 py-2 rounded-lg text-sm shadow-sm hover:shadow-md mx-1 w-full md:w-52 font-semibold text-center"
           >
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline-block mr-2">
               <path stroke-linecap="round" stroke-linejoin="round" d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
             </svg>
             <span class="inline-block">Novo Projeto</span>
         </router-link>
       </div>
     </div>
     <div class="grid grid-cols-12 gap-4 my-2">
       <div class="col-span-12 md:col-span-4">
         <input v-model="busca" type="text" name="busca" id="busca" :placeholder="controle === 'Projeto' ? 'Filtrando Nome Projeto' : 'Filtrando Nome Programa'" class="h-10 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
       </div>
       <div class="col-span-12 md:col-span-4">
          <select v-if="responsaveis.length" class="rounded-full" v-model="buscaResponsavel" name="responsavelProjeto" id="responsavelProjeto">
            <option :value="null">Responsável pelo projeto: Todos ({{ responsaveis.length }})</option>
            <option v-for="responsavel in responsaveis" :value="responsavel.nome" :key="responsavel._id">{{responsavel.nome}}</option>
          </select>  
      </div>
       <div class="col-span-12 md:col-span-2">
         <button @click="list" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
       </div>
       <div v-if="!$store.state.user.profissional && controle === 'Projeto'" class="col-span-12 md:col-span-6 md:justify-self-end">
         <router-link :to="`/painel/desativadas`" class="inline-block transition duration-200 bg-gray-700 hover:bg-gray-800 focus:bg-gray-800 focus:shadow-sm focus:ring-4 focus:ring-yellow-500 focus:ring-opacity-50 py-2 rounded-lg text-sm text-white shadow-sm hover:shadow-md mx-1 w-full md:w-52 font-semibold text-center mb-2">
           Projetos Finalizados
         </router-link>
       </div>
     </div>
     <div class="flex flex-col">
       <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
         <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
           <div v-if="controle === 'Projeto'">
             <table class="min-w-full">
               <thead class="border-b border-gray-200">
                 <tr>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-700 tracking-wider">
                     Projeto
                   </th>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-800 tracking-wider">
                     Andamento do Projeto
                   </th>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-800 tracking-wider">
                     Início
                   </th>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-800 tracking-wider">
                     Prazo (dias)
                   </th>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-800 tracking-wider">
                     Opções
                   </th>
                 </tr>
               </thead>
               <tbody class="bg-white divide-y divide-gray-200">
                 <tr v-for="item in empresas" :key="item._id">
                   <td class="px-1 py-3">
                     <div class="grid rounded-lg text-center">
                       <img class="w-24 md:w-auto md:h-16 justify-self-center mb-1" v-if="item && item.logo.filename" :src="`${url_api}/upload?mimetype=image/jpeg&filename=${item.logo.filename}&folder=empresas`"/>
                       <span class="font-normal text-base">{{ item.nome }} <span v-if="item.responsavelProjeto">- {{ item.responsavelProjeto }}</span></span>
                     </div>
                   </td>
                   <td class="px-1 py-2 whitespace-nowrap">
                     <div class="flex items-center justify-center">
                       <div class="font-normal text-base text-blue-500">
                         <CircularProgress
                           :size="65"
                           :strokeWidth="4"
                           :progress="parseInt((item.concluidos / item.total_tarefas) * 100) || 0"
                           :progressColor="'#5cb85c'"
                           :trackColor="'#d9534f'"
                         />
                       </div>
                     </div>
                   </td>

                   <td class="px-1 py-2 whitespace-nowrap">
                     <div class="flex items-center justify-center">
                     <div class="font-normal text-base text-gray-800">
                         {{ item.dataInicio ? item.dataInicio : '' | moment("DD/MM/YYYY") }}
                       </div>
                     </div>
                   </td>

                   <td class="px-1 py-2 whitespace-nowrap">
                     <div class="flex items-center justify-center">
                       <div class="font-normal text-base text-gray-800">
                         {{ item.dataPrevisao && item.dataInicio && $moment(item.dataPrevisao).diff($moment(), 'days') > -1 ? $moment(item.dataPrevisao).diff($moment(), 'days')  : item.dataPrevisao && item.dataInicio ?  item.tarefasConcluidas ? 'Concluído' : 'Atrasada' : '' }}
                       </div>
                     </div>
                   </td>

                   <td class="px-1 py-2">
                     <div class="flex items-center justify-center">
                       <button type="button" @click="setEmpresa(item)" class="inline-block text-sm_2 text-center	 2xl:text-sm font-medium text-white hover:bg-green-700 bg-green-600 rounded-xl py-1 px-3 my-1 mx-1">
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 2xl:w-4 2xl:h-4 inline-block">
                           <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                         </svg>
                         Acessar
                       </button>
                       <router-link type="button" v-if="$store.state.user.consultor || $store.state.user.admin" :to="`/painel/form/${item._id}`" class="inline-block text-sm_2 text-center	 2xl:text-sm font-medium text-white hover:bg-red-500 bg-red-600 rounded-xl py-1 px-3 my-1 mx-1">
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 2xl:w-4 2xl:h-4 inline-block">
                           <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                         </svg>
                         Editar
                       </router-link>
                       <button type="button" v-if="$store.state.user.consultor || $store.state.user.admin" @click="finalizarEmpresa(item)" class="inline-block text-sm_2 text-center	 2xl:text-sm font-medium text-white hover:bg-yellow-600 bg-yellow-500 rounded-xl py-1 px-3 my-1 mx-1">
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 2xl:w-4 2xl:h-4 inline-block">
                           <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                         </svg>
                         Finalizar
                       </button>
                     </div>
                   </td>
                 </tr>
               </tbody>
             </table>
           </div>
           <div v-if="controle === 'Programa'">
             <table class="min-w-full">
               <thead class="border-b border-gray-200">
                 <tr>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-700 tracking-wider">
                     Programa
                   </th>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-800 tracking-wider">
                     Atividades não finalizadas
                   </th>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-800 tracking-wider">
                     Riscos não analisados
                   </th>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-800 tracking-wider">
                     Pessoas sem treinamentos
                   </th>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-800 tracking-wider">
                     Contratos vencidos
                   </th>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-800 tracking-wider">
                     Requisições pendentes
                   </th>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-800 tracking-wider">
                     Incidentes pendentes
                   </th>
                   <th scope="col" class="px-1 py-2 text-center text-sm_2 font-semibold text-gray-800 tracking-wider">
                     Opções
                   </th>
                 </tr>
               </thead>
               <tbody class="bg-white divide-y divide-gray-200">
                 <tr v-for="item in empresas" :key="item._id">
                   <td class="px-1 py-3">
                     <div class="grid rounded-lg text-center">
                       <img class="w-24 justify-self-center mb-1" v-if="item && item.logo.filename" :src="`${url_api}/upload?mimetype=image/jpeg&filename=${item.logo.filename}&folder=empresas`"/>
                       <span class="font-normal text-base">{{ item.nome }}</span>
                     </div>
                   </td>
                   <td class="px-1 py-2 whitespace-nowrap">
                     <div class="flex items-center justify-center">
                       <div class="font-normal text-base text-blue-500">
                         <CircularProgress
                           :size="60"
                           :strokeWidth="4"
                           :progress="parseInt((item.atividades_nao_finalizadas / item.atividades_total) * 100) || 0"
                           :progressColor="'#d9534f'"
                           :trackColor="'#5cb85c'"
                         />
                       </div>
                     </div>
                   </td>
                   <td class="px-1 py-2 whitespace-nowrap">
                     <div class="flex items-center justify-center">
                       <div class="font-normal text-base text-blue-500">
                         <CircularProgress
                           :size="60"
                           :strokeWidth="4"
                           :progress="parseInt((item.riscos_nao_analisados / item.riscos_total) * 100) || 0"
                           :progressColor="'#d9534f'"
                           :trackColor="'#5cb85c'"
                         />
                       </div>
                     </div>
                   </td>
                   <td class="px-1 py-2 whitespace-nowrap">
                     <div class="flex items-center justify-center">
                       <div class="font-normal text-base text-blue-500">
                         <CircularProgress
                           :size="60"
                           :strokeWidth="4"
                           :progress="parseInt((item.pessoas_nao_treinadas / item.pessoas_total) * 100) || 0"
                           :progressColor="'#d9534f'"
                           :trackColor="'#5cb85c'"
                         />
                       </div>
                     </div>
                   </td>
                   <td class="px-1 py-2 whitespace-nowrap">
                     <div class="flex items-center justify-center">
                       <div class="font-normal text-base text-blue-500">
                         <CircularProgress
                           :size="60"
                           :strokeWidth="4"
                           :progress="parseInt((item.contratos_vencidos / item.contratos_total) * 100) || 0"
                           :progressColor="'#d9534f'"
                           :trackColor="'#5cb85c'"
                         />
                       </div>
                     </div>
                   </td>
                   <td class="px-1 py-2 whitespace-nowrap">
                     <div class="flex items-center justify-center">
                       <div class="font-normal text-base text-blue-500">
                         <CircularProgress
                           :size="60"
                           :strokeWidth="4"
                           :progress="parseInt((item.requisicao_pendentes / item.requisicao_total) * 100) || 0"
                           :progressColor="'#d9534f'"
                           :trackColor="'#5cb85c'"
                         />
                       </div>
                     </div>
                   </td>
                   <td class="px-1 py-2 whitespace-nowrap">
                     <div class="flex items-center justify-center">
                       <div class="font-normal text-base text-blue-500">
                         <CircularProgress
                           :size="60"
                           :strokeWidth="4"
                           :progress="parseInt((item.incidentes_pendentes / item.incidentes_total) * 100) || 0"
                           :progressColor="'#d9534f'"
                           :trackColor="'#5cb85c'"
                         />
                       </div>
                     </div>
                   </td>
                   <td class="px-1 py-2">
                     <div class="flex items-center justify-center">
                       <button type="button" @click="setEmpresa(item)" class="inline-block text-center	 text-sm_2 2xl:text-sm font-medium text-white hover:bg-green-700 bg-green-600 rounded-xl py-1 px-3 my-1 mx-1">
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 2xl:w-4 2xl:h-4 inline-block">
                           <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                         </svg>
                         Acessar
                       </button>
                       <router-link type="button" v-if="$store.state.user.consultor || $store.state.user.admin" :to="`/painel/form/${item._id}`" class="inline-block text-center	 text-sm_2 2xl:text-sm font-medium text-white hover:bg-red-500 bg-red-600 rounded-xl py-1 px-3 my-1 mx-1">
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 2xl:w-4 2xl:h-4 inline-block">
                           <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                         </svg>
                         Editar
                       </router-link>
                       <button type="button" v-if="$store.state.user.consultor || $store.state.user.admin" @click="finalizarEmpresa(item)" class="inline-block text-center	 text-sm_2 2xl:text-sm font-medium text-white hover:bg-yellow-600 bg-yellow-500 rounded-xl py-1 px-3 my-1 mx-1">
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 2xl:w-4 2xl:h-4 inline-block">
                           <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                         </svg>
                         Finalizar
                       </button>
                     </div>
                   </td>
                 </tr>
               </tbody>
             </table>
           </div>
           <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
         </div>
       </div>
     </div>
   </div>
   <modal name="finalizarEmpresa" :adaptive="true" :width="530" :height="'auto'" styles="border-radius: 12px">
     <div class="px-3 py-4 bg-yellow-500">
       <h2 class="text-2xl font-semibold text-black text-center mb-2">Finalizar !</h2>
       <p class="text-lg font-normal text-black text-center">Você deseja finalizar este {{ controle === 'Projeto' ? 'Projeto' : 'Programa' }} de qual forma ?</p>
       <div class="flex justify-center mt-4">
         <button v-if="empresa && empresa.controle === 'Projeto'" type="button" @click="confirmarFinalizacao('Transformar em programa')" class="inline-block text-sm font-normal text-white hover:bg-gray-600 bg-gray-500 w-2/4 rounded-xl py-1 px-3 my-1 mx-1">
           Transformar em programa
         </button>
         <button v-if="empresa && empresa.controle === 'Programa'" type="button" @click="confirmarFinalizacao('Transformar em projeto')" class="inline-block text-sm font-normal text-white hover:bg-gray-600 bg-gray-500 w-2/4 rounded-xl py-1 px-3 my-1 mx-1">
           Transformar em projeto
         </button>
         <button v-if="empresa && empresa.controle === 'Programa'" type="button" @click="confirmarFinalizacao('Arquivar o programa')" class="inline-block text-sm font-normal text-white hover:bg-gray-600 bg-gray-500 w-2/4 rounded-xl py-1 px-3 my-1 mx-1">
           Arquivar o programa
         </button>
         <button  v-if="empresa && empresa.controle === 'Projeto'" type="button" @click="confirmarFinalizacao('Arquivar o projeto')" class="inline-block text-sm font-normal text-white hover:bg-gray-600 bg-gray-500 w-2/4 rounded-xl py-1 px-3 my-1 mx-1">
           Arquivar o projeto
         </button>
         <button type="button" @click="confirmarFinalizacao('Transferir a titularidade')" class="inline-block text-sm font-normal text-white hover:bg-gray-600 bg-gray-500 w-2/4 rounded-xl py-1 px-3 my-1 mx-1">
           Transferir titularidade
         </button>
       </div>
     </div>
   </modal>
   <modal name="confirmarFinalizacao" :adaptive="true" :width="530" :height="'auto'" styles="border-radius: 12px">
     <div class="px-3 py-4 bg-yellow-500">
       <h2 class="text-2xl font-semibold text-black text-center mb-4">{{ opcao.nome }}</h2>
       <p class="text-lg font-normal text-black text-center">{{ opcao.texto }}</p>
       <div class="flex justify-center mt-4">
         <button @click="finalizar(opcao.nome)" type="button" class="inline-block text-sm font-normal text-white hover:bg-gray-600 bg-gray-500 w-2/4 rounded-xl py-2 px-3 my-1 mx-1">
           {{ opcao.button }}
         </button>
       </div>
     </div>
   </modal>
   <modal name="TransferirTitularidade" :adaptive="true" :width="400" :height="'auto'" styles="border-radius: 12px">
     <div class="px-3 py-4 bg-yellow-500">
       <h2 class="text-2xl font-semibold text-black mb-4">Transferir a titularidade</h2>
       <label for="transferirnome" class="block text-sm font-medium">Nome completo do novo responsavel</label>
       <input type="text" v-model="transferir.nome" name="transferirnome" id="transferirnome" placeholder="Nome responsável" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
       
       <label for="transferirempresa" class="block text-sm font-medium mt-2">Empresa ou organização</label>
       <input type="text" v-model="transferir.empresaNome" name="transferirempresa" id="transferirempresa" placeholder="Empresa" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">

       <label for="transferircnpj" class="block text-sm font-medium mt-2">CNPJ</label>
       <input type="text" v-model="transferir.cnpj" name="transferircnpj" id="transferircnpj" placeholder="CNPJ Empresa" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">

       <label for="transferiremail" class="block text-sm font-medium mt-2">E-mail</label>
       <input type="email" v-model="transferir.email" name="transferiremail" id="transferiremail" placeholder="Email" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">

       <label for="transferirtelefone" class="block text-sm font-medium mt-2">Telefone</label>
       <input type="text" v-model="transferir.telefone" name="transferirtelefone" id="transferirtelefone" placeholder="Telefone" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
       
       <button @click="transferirTitularidade()" type="button" class="inline-block mt-4 text-sm font-normal text-white hover:bg-gray-600 bg-gray-500 w-2/4 rounded-xl py-2 px-3 my-1 mx-1">
         Transferir
       </button>
     </div>
   </modal>
 </div>
</template>

<script>
import CircularProgress from "@/components/CircularProgress.vue";
export default {
 components: {
   CircularProgress,
 },
 data() {
   return {
     empresas: [],
     empresa: null,
     opcao: {
       nome: "",
       texto: "",
       button: "",
     },
     transferir: {
       nome: "",
       empresaNome: "",
       cnpj: "",
       email: "",
       telefone: ""
     },
     controle: "Projeto",
     route: 'empresas',
     busca: "",
     buscaResponsavel: null,
     page: 1,
     perPage: 20,
     total: 0,
     responsaveis: []
   }
 },
 methods: {
   async list() {
     const listReq = await this.$http.post(`/v1/${this.route}/painel`, { controle: this.controle, limit: this.perPage, busca: this.busca, buscaResponsavel: this.buscaResponsavel });
     this.empresas = listReq.data.data;
     this.total = listReq.data.total;
     this.responsaveis = listReq.data.responsaveis
   },
   finalizarEmpresa(empresa){
     this.empresa = empresa;
     this.$modal.show('finalizarEmpresa');
   },
   confirmarFinalizacao(selecionado){
     this.$modal.hide('finalizarEmpresa');
     if(selecionado === "Transformar em programa"){
       this.opcao = {
         nome: "Transformar em programa",
         texto: "Atenção: Seu projeto dará lugar ao programa, e estará acessível clicando em “programas”. Deseja realmente transformá-lo em programa?",
         button: "Transformar em programa",
       }
     }
     if(selecionado === "Transformar em projeto"){
       this.opcao = {
         nome: "Transformar em projeto",
         texto: "Atenção: Seu programa dará lugar ao projeto, e estará acessível clicando em projetos. Deseja realmente transformá-lo em projeto?",
         button: "Transformar em projeto",
       }
     }
     if(selecionado === "Arquivar o programa"){
       this.opcao = {
         nome: "Arquivar o programa",
         texto: "Atenção: Seu programa será arquivado e estará acessível clicando em “projetos finalizados”. Deseja realmente arquivá-lo?",
         button: "Arquivar programa",
       }
     }
     if(selecionado === "Arquivar o projeto"){
       this.opcao = {
         nome: "Arquivar o projeto",
         texto: "Atenção: Seu projeto será arquivado e estará acessível clicando em “projetos finalizados”. Deseja realmente arquivá-lo?",
         button: "Arquivar projeto",
       }
     }
     if(selecionado === "Transferir a titularidade"){
       this.opcao = {
         nome: "Transferir a titularidade",
         texto: "Atenção: Transferindo a titularidade você não terá mais acesso ao projeto. Informe abaixo os dados do futuro titular/responsável",
         button: "Informar",
       }
     }
     this.$modal.show('confirmarFinalizacao');
   },
   async finalizar(selecionado){
     if(!this.empresa || !this.empresa._id) return this.$vToastify.success("Nenhuma empresa selecionada");

     if(selecionado === "Transformar em programa"){
       await this.$http.put(`/v1/${this.route}`,{ _id: this.empresa._id, controle: "Programa" });
       this.$vToastify.success("Transformado em programa com sucesso!"); 
     }

     if(selecionado === "Transformar em projeto"){
       await this.$http.put(`/v1/${this.route}`,{ _id: this.empresa._id, controle: "Projeto" });
       this.$vToastify.success("Transformado em projeto com sucesso!"); 
     }

     if(selecionado === "Arquivar o projeto" || selecionado === "Arquivar o programa"){
       await this.$http.delete(`/v1/${this.route}/${this.empresa._id}`);
       this.$vToastify.success("Projeto arquivado com sucesso!"); 
     }

     if(selecionado === "Transferir a titularidade"){
       this.$modal.show('TransferirTitularidade');
     }

     await this.list();
     if(selecionado !== "Transferir a titularidade") this.empresa = null;
     this.$modal.hide('confirmarFinalizacao');
   },
   async transferirTitularidade(){
     if(!this.transferir.email || !this.transferir.empresaNome || !this.transferir.nome) return this.$vToastify.error("Nome, Empresa, email são obrigatório para transferência!"); 
     
     this.transferir.empresa = this.empresa;

     await this.$http.post(`/v1/${this.route}/transferir`, this.transferir);
     this.$vToastify.success("Solicitação de transferência efetuada com sucesso!"); 

     this.$modal.hide('TransferirTitularidade');
     this.transferir = { nome: "", empresa: "", cnpj: "", email: "", telefone: ""};
     this.empresa = null;
   },
   setEmpresa(empresa) {
     this.$store.commit('setEmpresa', empresa);
     localStorage.setItem("empresa", JSON.stringify(empresa));
     this.$store.commit('setColor', { colorPrincipal: empresa.colorPrincipal, colorSecundaria: empresa.colorSecundaria });
     this.$vToastify.success(`Filtrando pela empresa ${empresa.nome}`);
   },
   async setPage(page) {
     this.page = page;
     const skip = (page - 1) * this.perPage;
     const limit = this.perPage;

     const listReq = await this.$http.post(`/v1/${this.route}/painel`, { controle: this.controle, limit, skip });
     this.empresas = listReq.data.data;
     this.total = listReq.data.total;

     window.scrollTo({ top: 0, behavior: 'smooth' });
   }
 },
 async beforeMount() {
   this.list();
 },
}
</script>